import './src/main.css'

export const onRouteUpdate = ({ location }) => {
  const isCareersPage = location.pathname.includes('careers');

  if (typeof window !== 'undefined') {
    const head = document.querySelector('head');
    let metaTag = head.querySelector('meta[name="robots"]');

    if (isCareersPage) {
      // If on careers page, ensure noindex meta tag is present
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.name = 'robots';
        metaTag.content = 'noindex';
        head.appendChild(metaTag);
      } else {
        metaTag.content = 'noindex';  // Update if it exists
      }
    } else {
      // If not on careers page, remove the noindex meta tag
      if (metaTag && metaTag.content === 'noindex') {
        head.removeChild(metaTag);
      }
    }
  }
};
